import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Input } from 'reactstrap';
import constants from '../utils/constants';
import { ESignService } from '../services/sign.service';
import { ToastContext } from '../contexts/ToastContext';
import Met1LogoImg from '../assets/img/meta1-logo.png';

let popupWindow;

export const Payment = () => {
    const [isMobile, setIsMobile] = useState(true);
    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [paymentRedirectUrl, setPaymentRedirectUrl] = useState('');
    const [paymentResult, setPaymentResult] = useState(constants.STATUS.NONE);
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(0);
    const [tabReference, setTabReference] = useState(undefined);

    const location = useLocation();
    const { notify } = useContext(ToastContext);

    const onPaymentDone = () => {
        const isMobile = JSON.parse(localStorage.getItem(constants.IS_MOBILE));
        const redirectUrl = localStorage.getItem(constants.REDIRECT_URL);
        setPaymentResult(constants.STATUS.SUCCESS);

        if (isMobile) {
            window.location.href = redirectUrl || 'about:blank';
        } else {
            popupWindow?.close();
        }
    };

    useEffect(() => {
        const onStorage = (e) => {
            if (e.key === 'plisio' && e.newValue === 'true') {
                if (tabReference) {
                    tabReference?.close();
                } else {
                    window.close();
                }
            }
        };

        window.removeEventListener('storage', onStorage);
        window.addEventListener('storage', onStorage);

        return () => window.removeEventListener('storage', onStorage);
    }, [tabReference]);

    useEffect(() => {
        if (window.document.referrer === constants.STRIPE_URL || window.location.href.includes('plisio=true')) {
            const queryParams = new URLSearchParams(location.search);
            const status = queryParams.get('status');

            localStorage.setItem(constants.PAYMENT_RESULT, status);
            localStorage.setItem('plisio', true);

            if (status === constants.STATUS.SUCCESS) {
                onPaymentDone();
            }

            const isMobileParam = JSON.parse(localStorage.getItem(constants.IS_MOBILE));
            setIsMobile(isMobileParam);

            const redirectUrl = localStorage.getItem(constants.REDIRECT_URL);
            setPaymentRedirectUrl(redirectUrl);
        } else {
            const queryParams = new URLSearchParams(location.search);
            const isMobileParam = JSON.parse(queryParams.get(constants.IS_MOBILE));

            setIsMobile(isMobileParam);
            localStorage.setItem(constants.IS_MOBILE, isMobileParam);

            const token = queryParams.get('token');

            ESignService.getPole(token)
                .then((poleResponse) => {
                    if (poleResponse.data) {
                        const { email, firstName, lastName, redirectUrl } = poleResponse.data;
                        localStorage.setItem(constants.REDIRECT_URL, redirectUrl);
                        setUserEmail(email);
                        setUsername([firstName, lastName].join(' '));
                        setPaymentRedirectUrl(redirectUrl);
                    } else {
                        notify('Token is invalid. Please check your token manually has been changed.');
                    }
                })
                .catch(() => {
                    notify('Something went wrong. Can you try again?');
                });
        }
    }, [location, notify]);

    useEffect(() => {
        if (paymentResult === constants.STATUS.FAILURE) {
            notify('Payment Request failed, please try again.');
        } else if (paymentResult === constants.STATUS.SUCCESS) {
            // If redirect URL is set then redirect to correct URL
            if (paymentRedirectUrl != null && paymentRedirectUrl.trim().length > 0) {
                window.location.href = `${paymentRedirectUrl}?signature=success`;
            } else {
                // Else assume that it might be mobile app and try to open mobile app
                window.location.href = `mesign://?signature=success`;
            }

            const postMessageData = { "m-e-sign-data": {"payment": "success"}};

            if (typeof Meta1ESign !== "undefined") {
                // eslint-disable-next-line no-undef
                Meta1ESign.postMessage(JSON.stringify(postMessageData))
            }

            if (window.parent) {
                window.parent.postMessage(JSON.stringify(postMessageData), '*')
            }

            if (window.opener) {
                window.opener.postMessage(JSON.stringify(postMessageData), '*')
            }
        }
    }, [notify, paymentRedirectUrl, paymentResult]);

    const openPopupWindow = (url) => {
        if (isMobile || paymentMethod === 0) {
            window.location.href = url;
        } else {
            popupWindow = window.open(url, '_blank');
            setTabReference(popupWindow);
        }
    };

    const hashCode = (str) => {
        let hash = 0;

        if (str.length === 0) return hash;

        for (let i = 0; i < str.length; i++) {
            const chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0;
        }

        return Math.abs(hash);
    };

    const onPay = useCallback(async () => {
        setIsLoading(true);

        try {
            const resp = await ESignService.generateTokenForSign(userEmail);

            if (!resp) {
                notify('ESignature service error. Try again');
                return;
            }

            const paymentParams = {
                token: resp.headers.authorization,
                name: username,
                email: userEmail,
            };

            if (paymentMethod === 0) {
                const [getPaymentUrlResponse, getPaymentUrlError] = await ESignService.getPaymentUrl(paymentParams);

                if (getPaymentUrlError) {
                    console.error(getPaymentUrlError);
                } else if (getPaymentUrlResponse?.data?.url) {
                    openPopupWindow(getPaymentUrlResponse?.data?.url);
                }
            } else {
                const [getPaymentUrlResponse, getPaymentUrlError] = await ESignService.getCryptoPaymentUrl({
                    ...paymentParams,
                    amount: 1,
                    tradeCurrency: 'USD',
                    orderName: `1$ payment for ${userEmail}`,
                    orderNum: hashCode(userEmail + Date.now().toString()``),
                });

                if (getPaymentUrlError) {
                    console.error(getPaymentUrlError);
                } else if (getPaymentUrlResponse?.data?.url) {
                    openPopupWindow(getPaymentUrlResponse?.data?.url);
                }
            }
        } finally {
            setIsLoading(false);
        }
    }, [userEmail, username, paymentMethod, notify]);

    const onChangePaymentMethod = (e) => {
        setPaymentMethod(Number(e.target.value));
    };

    return (
        <Container>
            {[constants.STATUS.NONE, constants.STATUS.FAILURE].includes(paymentResult) ? (
                <>
                    <Row className="mt-5">
                        <Col className="d-flex">
                            <img
                                alt="META 1 Logo"
                                src={Met1LogoImg}
                                className="mx-auto"
                                style={{ width: '100%', maxWidth: 150 }}
                            />
                        </Col>
                    </Row>
                    <h4 className="my-3 text-center text-white">Pay Membership Dues</h4>
                    <Row className="payment-body">
                        <Col sm="12" className="d-flex justify-center text-white">
                            <p style={{ textAlign: 'center', color: '#ffc000', fontWeight: '600' }}>
                                $1.00 META Private Membership Association Lifetime Membership
                            </p>
                        </Col>
                        <Col sm="12" className="d-flex justify-center text-white">
                            <p style={{ textAlign: 'center', color: '#999999' }}>
                                Select either Fiat currency to pay with credit or debit card or cryptocurrency from the menu below.
                            </p>
                        </Col>
                        <Col sm="12" className="d-flex justify-center py-2">
                            <Input type="select" name="select" id="exampleSelect" onChange={onChangePaymentMethod}>
                                <option value={0}>Fiat currency</option>
                                <option value={1}>Cryptocurrency</option>
                            </Input>
                        </Col>

                        <Col sm="12" className="d-flex justify-center py-2">
                            <Button color="success" disabled={isLoading} onClick={onPay}>
                                {isLoading ? 'Loading' : 'Pay now'}
                            </Button>
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    <Row className="mt-5">
                        <Col className="d-flex">
                            <img
                                alt="META 1 Logo"
                                src={Met1LogoImg}
                                className="mx-auto"
                                style={{ width: '100%', maxWidth: 150 }}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col
                            className="pt-5"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p
                                style={{
                                    fontSize: '22px',
                                    fontWeight: '400',
                                    lineHeight: '1.25em',
                                    textAlign: 'center',
                                    color: '#aaaaaa',
                                    maxWidth: '800px',
                                }}
                            >
                                Thank you for becoming a member of the META Private Membership
                                Association. You can close this screen and return to the wallet
                                creation window.
                            </p>
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
};
